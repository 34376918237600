<template>
  <base-section id="404" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col cols="10">
          <base-subheading class="success--text" :title="$t('checkPayment')" />

          <base-subheading space="8" class="text-h6" :title="$t(titleKey)" />

          <v-row justify="center">
            <v-col cols="12"><base-loading v-if="loading" /></v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="11">
                  <base-btn block :to="`/menu`">
                    {{ $t("returnToMenu") }}
                  </base-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import { orderCtrl } from "@/controllers";
export default {
  name: "CheckPaymentStatusSection",

  provide: {
    heading: { align: "center" },
  },
  data() {
    return {
      titleKey: "waitingConfirmPayment",
      loading: true,
      idInterval: null,
    };
  },
  mounted() {
    const { requestId } = this.$route.params;
    this.idInterval = setInterval(() => {
      orderCtrl
        .getOrderById(requestId || 422)
        .then(({ status }) => {
          if (status === "borrador") return;
          this.titleKey =
            status === "cancelado" ? "cancelPayment" : "confirmPayment";
          this.loading = false;
          return clearInterval(this.idInterval);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);
  },
  beforeDestroy: function () {
    clearInterval(this.idInterval);
    this.$store.dispatch("cart/clearCart");
  },
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "100vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
